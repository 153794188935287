<template>
  <div class="sub-menu bg-purple-50 dark:bg-slate-800 p-0">
    <div v-if="hasSubMenu" class="flex justify-between p-0">
      <button
        class="header-section flex items-center text-base font-medium cursor-auto h-11 w-full text-slate-700 dark:text-slate-100 p-0"
      >
        <fluent-icon icon="book" size="20" class="icon icon--font ml-4 mr-4" />
        <span class="flex font-medium text-button items-start w-40">{{
          $t(`SIDEBAR.${menuItem.label}`)
        }}</span>
      </button>
      <div v-if="menuItem.showNewButton" class="flex items-start">
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          icon="person"
          class="p-0 ml-2"
          @click="onClickOpen"
        />
      </div>
    </div>
    <router-link v-else :to="menuItem && menuItem.toState" custom class="p-0" style="padding:0">
    <button
      class="text-sm flex cursor-pointer items-center font-medium h-11 hover:text-purple-600 dark:hover:text-purple-600 w-full text-slate-700 dark:text-slate-100"
      >

      <fluent-icon :icon="menuItem.icon" size="30" class="icon icon--font ml-10" :class="{'text-purple-600':isActiveView}"/>
      <span class="flex items-start font-medium text-button w-full ml-3" :class="{'text-purple-600':isActiveView}">
      {{
        $t(`SIDEBAR.${menuItem.label}`)
      }}</span>
    </button>
    </router-link>

    <ul v-if="hasSubMenu" class="mb-0 ml-0 list-none">
      <secondary-child-nav-item
        v-for="child in menuItem.children"
        :key="child.id"
        :to="child.toState"
        :label="child.label"
        :label-color="child.color"
        :should-truncate="child.truncateLabel"
        :icon="computedInboxClass(child)"
        :warning-icon="computedInboxErrorClass(child)"
        :show-child-count="showChildCount(child.count)"
        :child-item-count="child.count"
      />
      <router-link
        v-if="showItem(menuItem)"
        v-slot="{ href, navigate }"
        :to="menuItem.toState"
        custom
      >
        <li class="">
          <a :href="href" style="padding:0;">
            <button
              class="text-sm flex cursor-pointer items-center font-medium h-11 hover:text-purple-600 dark:hover:text-purple-600 w-full text-slate-700 dark:text-slate-100"
              @click="e => newLinkClick(e, navigate)"
              >
              <fluent-icon icon="add" size="25" class="icon icon--font ml-10" />
              <span class="flex items-start font-medium text-button w-full ml-3">{{
                $t(`SIDEBAR.${menuItem.newLinkTag}`)
              }}</span>
            </button>

          </a>
        </li>

      </router-link>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import adminMixin from '../../../mixins/isAdmin';
import configMixin from 'shared/mixins/configMixin';
import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';

import SecondaryChildNavItem from './SecondaryChildNavItem.vue';
import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../../../store/modules/conversations/helpers/actionHelpers';
import PanelDropown from './PanelDropdown.vue';

export default {
  components: { SecondaryChildNavItem, PanelDropown },
  mixins: [adminMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      globalConfig: 'globalConfig/get',
    }),
    isCountZero() {
      return this.menuItem.count === 0;
    },
    isActiveView() {
      return this.computedClass.includes('active-view');
    },
    hasSubMenu() {
      return !!this.menuItem.children;
    },
    isMenuItemVisible() {
      if (this.menuItem.globalConfigFlag) {
        // this checks for the `csmlEditorHost` flag in the global config
        // if this is present, we toggle the CSML editor menu item
        // TODO: This is very specific, and can be handled better, fix it
        return !!this.globalConfig[this.menuItem.globalConfigFlag];
      }

      let isFeatureEnabled = true;
      if (this.menuItem.featureFlag) {
        isFeatureEnabled = this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }

      if (this.menuItem.isEnterpriseOnly) {
        if (!this.isEnterprise) return false;
        return isFeatureEnabled || this.globalConfig.displayManifest;
      }

      if (this.menuItem.featureFlag) {
        return this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }

      return isFeatureEnabled;
    },
    isAllConversations() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isMentions() {
      return (
        isOnMentionsView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_mentions'
      );
    },
    isUnattended() {
      return (
        isOnUnattendedView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_unattended'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isCurrentRoute() {
      return this.$store.state.route.name.includes(this.menuItem.toStateName);
    },

    computedClass() {
      // If active inbox is present, do not highlight conversations
      if (this.activeInbox) return ' ';
      if (
        this.isAllConversations ||
        this.isMentions ||
        this.isUnattended ||
        this.isCurrentRoute
      ) {
        return 'bg-woot-25 dark:bg-slate-800 text-woot-500 dark:text-woot-500 hover:text-woot-500 dark:hover:text-woot-500 active-view';
      }
      if (this.hasSubMenu) {
        if (
          this.isTeamsSettings ||
          this.isInboxSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'bg-woot-25 dark:bg-slate-800 text-woot-500 dark:text-woot-500 hover:text-woot-500 dark:hover:text-woot-500 active-view';
        }
        return 'hover:text-slate-700 dark:hover:text-slate-100';
      }

      return 'hover:text-slate-700 dark:hover:text-slate-100';
    },
  },
  methods: {
    computedInboxClass(child) {
      const { type, phoneNumber } = child;
      if (!type) return '';
      let classByType = getInboxClassByType(type, phoneNumber);
      if(classByType == 'cloud'){
        classByType = 'whatsapp'
      }
      return classByType;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      } else if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label');
        }
      }
    },
    showItem(item) {
      return this.isAdmin && !!item.newLink;
    },
    onClickOpen() {
      this.$emit('open');
    },
    showChildCount(count) {
      return Number.isInteger(count);
    },
  },
};
</script>

<template>
  <div
    class="h-full overflow-auto w-72 flex flex-col bg-white dark:bg-slate-900 border-r dark:border-slate-800/50 rtl:border-r-0 rtl:border-l border-slate-50 text-sm pb-8 p-0 justify-between"
  >
    <div class="flex flex-col items-center">
      <router-link :to="newHomeURL">
        <img
          src="../../../../shared/assets/img/logo-dark.svg"
          alt=""
          class="w-full h-10 mt-6 mb-6 block dark:hidden sticky top-0 z-10"
        />
      </router-link>

      <router-link :to="newHomeURL">
        <img
          src="../../../../shared/assets/img/logo.svg"
          alt=""
          class="w-full h-10 mt-6 mb-6 hidden dark:block sticky top-0 z-10"
        />
      </router-link>
      <div
        class="menu-container scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200"
      >
        <PanelDropdownNavItem
          v-for="menuItem in menuItems"
          :key="menuItem.toState"
          :icon="menuItem.icon"
          :name="menuItem.label"
          :to="menuItem.toState"
          :is-child-menu-active="menuItem.key === activeMenuItem"
        >
          <slot></slot>
        </PanelDropdownNavItem>
        <NotificationSecundary @open-notification-panel="openNotificationPanel"/>
      </div>
      <!--       <div class="flex justify-end pb-6">
        <help-bell @open-help-panel="openHelpPanel"/>
      </div> -->
    </div>
  </div>
</template>

<script>
import PanelDropdownNavItem from './PanelDropdownNavItem.vue';
import { frontendURL } from '../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import AccountContext from './AccountContext.vue';
import NotificationSecundary from './NotificationSecundary.vue';
import HelpBell from './HelpBell.vue';
import { mapGetters } from 'vuex';
import accountMixin from '../../../mixins/account';
import wootConstants from 'dashboard/constants/globals';

export default {
  components: {
    AccountContext,
    SecondaryNavItem,
    PanelDropdownNavItem,
    NotificationSecundary,
    HelpBell,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
    accountIdChild: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentRole: {
      type: String,
      default: '',
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
    expanded: Boolean,
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
    };
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    showAddLabelPopup() {
      this.$emit('add-label');
    },
    openMenu() {
      this.$emit('open-menu');
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
  },
  mixins: [accountMixin],
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    newHomeURL() {
      return this.addAccountScoping('dashboard');
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #541D45;
  border-radius: 3px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

.menu-container {
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
}
</style>

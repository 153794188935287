<template>
  <div
    class="flex justify-between items-center w-full h-24 px-4 py-6 bg-white dark:bg-slate-800 border-b border-black-700"
  >
    <h1
      class="poppins ml-12 text-xl font-bold mb-0 flex items-center text-slate-900 dark:text-slate-100"
    >
      <back-button
        v-if="showBackButton"
        :button-label="backButtonLabel"
        :back-url="backUrl"
      />
      <fluent-icon
        v-if="icon"
        :icon="icon"
        :class="iconClass"
        class="mr-2 ml-1 rtl:ml-2 rtl:mr-1 hidden md:block"
      />
      <slot />
      <span class="text-slate-900 font-medium text-2xl dark:text-slate-100">
        {{ headerTitle }}
      </span>
    </h1>
    <router-link
      v-if="showNewButton && isAdmin"
      :to="buttonRoute"
      class="button success button--fixed-top px-3.5 py-1 rounded-[5px] flex gap-2"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content">
        {{ buttonText }}
      </span>
    </router-link>
    <button class="custom-button-over w-[270px] bg-white dark:bg-slate-800"></button>
    <div class="flex gap-2">
      <agent-details @toggle-menu="toggleOptions" :opened="showOptionsMenu" class="teste"/>
      <options-menu
        :show="showOptionsMenu"
        :large="large"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../components/widgets/BackButton.vue';
import adminMixin from '../../../mixins/isAdmin';
import AgentDetails from '../../../components/layout/sidebarComponents/AgentDetails.vue';
import OptionsMenu from '../../../components/layout/sidebarComponents/OptionsMenu.vue';

export default {
  components: {
    BackButton,
    AgentDetails,
    OptionsMenu,
  },
  methods: {
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleOptions(widthButton) {
      console.log(widthButton);
      this.large = widthButton;
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
  },
  data() {
    return {
      large: 0,
      showOptionsMenu: false,
    };
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    buttonRoute: {
      default: '',
      type: String,
    },
    buttonText: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    showBackButton: { type: Boolean, default: false },
    showNewButton: { type: Boolean, default: false },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    backButtonLabel: {
      type: String,
      default: '',
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.poppins {
  font-family: Poppins;
}

.teste{
  position: fixed;
  top: 24px;
  right: 15px;
}

.custom-button-over {
  height: 45.73px;
  border-radius: 22.865px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  padding: 0 16px;
}
</style>

<template>
  <router-link
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
  >
    <button
      class="text-sm flex cursor-pointer items-center font-medium h-11 w-full text-slate-700 dark:text-slate-100 hover:text-purple-600 dark:hover:text-purple-600"
      @click="navigate"
      :href="href"
    >
        <fluent-icon
         v-if="icon"
          :icon="icon"
          size="25"
          class="icon icon--font ml-10"
          :class="{ 'text-purple-600': isActive }"
        />
      <span
        v-if="labelColor"
        class="flex  items-start rounded-sm bg-slate-100 ml-10 h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900"
        :class="{ 'text-purple-600': isActive }"
        :style="{ backgroundColor: labelColor }"
      />
      <div
        class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"
      >
        <span
          :title="menuTitle"
          class="flex items-start font-medium text-button w-full ml-3"
          :class="{ 'text-purple-600': isActive }"
        >
          {{ label }}
        </span>
        <span
          v-if="showChildCount"
          class="bg-slate-50 dark:bg-slate-700 rounded text-xxs font-medium mx-1 py-0 px-1"
          :class="
            isCountZero
              ? `text-slate-300 dark:text-slate-700`
              : `text-slate-700 dark:text-slate-50`
          "
        >
          {{ childItemCount }}
        </span>
      </div>
      <span v-if="warningIcon" class="inline-flex rounded-sm mr-1 bg-slate-100">
        <fluent-icon
          v-tooltip.top-end="$t('SIDEBAR.FACEBOOK_REAUTHORIZE')"
          class="text-xxs"
          :icon="warningIcon"
          size="12"
        />
      </span>
    </button>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showIcon() {
      return {
        'overflow-hidden whitespace-nowrap text-ellipsis': this.shouldTruncate,
      };
    },
    isCountZero() {
      return this.childItemCount === 0;
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
  },
};
</script>
